import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'

const Loader = () => {
  return (
    <>
      <Helmet>
        <title>Loading... | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color="primary" size={50} />
      </Box>
    </>
  )
}

export default Loader
