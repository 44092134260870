import { Suspense } from 'react'
import ScrollToTop from './components/ScrollToTop'
import Loader from './components/Loader'
import Routes from './routes/Routes'
import { BrowserRouter, Route, Routes as RouterRoutes } from 'react-router-dom'
import { styled } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import ThemeProvider from './theme'
import { AuthContextProvider } from './context/AuthContext'
import { CategoryProvider } from './context/CategoryContext'
import { HelmetProvider } from 'react-helmet-async'
import './css/Font.css'
import HomeLoader from './components/HomeLoader'

// alert notification
const StyledSnackbarProvider = styled(SnackbarProvider)`
  // &.notistack-MuiContent-success {
  //   background-color: #ffffff;
  //   color: #000000;
  // }
  ,
  &.notistack-MuiContent {
    box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.06),
      0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
`

const App = () => {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <HelmetProvider>
          <ThemeProvider>
            <CategoryProvider>
              <ScrollToTop />
              <StyledSnackbarProvider
                // iconVariant={{
                //   success: <img src={success} alt="success" />,
                //   error: <img src={error} alt="error" />,
                //   warning: '⚠️',
                //   info: 'ℹ️',
                // }}
                hideIconVariant
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <Suspense
                  fallback={
                    window.location.pathname === '/' ? (
                      <HomeLoader />
                    ) : (
                      <Loader />
                    )
                  }
                >
                  <Routes />
                </Suspense>
              </StyledSnackbarProvider>
            </CategoryProvider>
          </ThemeProvider>
        </HelmetProvider>
      </BrowserRouter>
    </AuthContextProvider>
  )
}

export default App
