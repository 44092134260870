import { useSnackbar } from 'notistack'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
  const { dispatch } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()

  const logout = () => {
    // remove token from storage
    localStorage.removeItem('token')
    localStorage.removeItem('reportedItems')

    enqueueSnackbar('You are successfully logged out', {
      variant: 'success',
    })

    // dispatch logout action
    dispatch({ type: 'LOGOUT' })
  }

  return { logout }
}
