import imageCompression from 'browser-image-compression'
import { blobToWebP } from 'webp-converter-browser'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'

// export const CompressImage = async (file, isResize, maxFileSize = 2) => {
//   const options = {
//     maxSizeMB: maxFileSize,
//     useWebWorker: true,
//     maxIteration: 10,
//     initialQuality: 0.9,
//     ...(isResize ? { maxWidthOrHeight: 1024 } : {}),
//   }

//   if (file.size <= options.maxSizeMB * 1024 * 1024) {
//     return file
//   }

//   try {
//     const compressedFile = await imageCompression(file, options)

//     if (compressedFile.size > file.size) {
//       return file
//     }

//     return compressedFile
//   } catch (error) {
//     console.error('Image compression failed:', error)
//     return file
//   }
// }

export const CompressImage = async (file, isResize, maxFileSize = 2) => {
  const options = {
    maxSizeMB: maxFileSize,
    useWebWorker: true,
    maxIteration: 10,
    initialQuality: 0.9,
    ...(isResize ? { maxWidthOrHeight: 1024 } : {}),
  }

  if (file.size <= options.maxSizeMB * 1024 * 1024) {
    return await convertToWebP(file)
  }

  try {
    const compressedFile = await imageCompression(file, options)

    if (compressedFile.size > file.size) {
      return await convertToWebP(file)
    }

    return await convertToWebP(compressedFile)
  } catch (error) {
    console.error('Image compression failed:', error)
    return await convertToWebP(file)
  }
}

async function convertToWebP(file) {
  try {
    const webpBlob = await blobToWebP(file)
    return new File([webpBlob], file.name.replace(/\.[^/.]+$/, '.webp'), {
      type: 'image/webp',
    })
  } catch (error) {
    console.error('WebP conversion failed:', error)
    return file
  }
}

export const CompressVideo = async (file, onProgress) => {
  const ffmpeg = new FFmpeg()
  await ffmpeg.load()

  ffmpeg.on('progress', ({ progress }) => {
    onProgress(Math.round(progress * 100))
  })

  const inputFileName =
    'input' + file.name.substring(file.name.lastIndexOf('.'))
  const outputFileName = 'output.mp4'

  await ffmpeg.writeFile(inputFileName, await fetchFile(file))

  await ffmpeg.exec([
    '-i',
    inputFileName,
    '-c:v',
    'libx264',
    '-crf',
    '28', // Increased CRF for faster encoding and smaller file size
    '-preset',
    'ultrafast', // Fastest encoding preset
    '-c:a',
    'aac',
    '-b:a',
    '96k', // Reduced audio bitrate
    '-ac',
    '2', // Force stereo audio
    '-maxrate',
    '2M', // Reduced max bitrate
    '-bufsize',
    '4M', // Adjusted buffer size
    '-movflags',
    '+faststart',
    '-vf',
    'scale=-2:480', // Reduced resolution to 480p
    '-r',
    '24', // Limit frame rate to 24 fps
    '-sn', // Remove subtitles
    '-threads',
    '0', // Use all available CPU threads
    outputFileName,
  ])

  const data = await ffmpeg.readFile(outputFileName)
  const compressedBlob = new Blob([data.buffer], { type: 'video/mp4' })
  return compressedBlob
}

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const numDifferentiation = (val) => {
  if (val === undefined || val === null) return null
  if (val >= 10000000) val = parseFloat((val / 10000000).toFixed(2)) + ' Cr'
  else if (val >= 100000) val = parseFloat((val / 100000).toFixed(2)) + ' Lac'
  else if (val >= 1000) val = parseFloat((val / 1000).toFixed(2)) + ' K'
  return val < 10 ? '0' + val.toString() : val.toString()
}

export function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1)
}
