import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'

const CategoryContext = createContext()

export const CategoryProvider = ({ children }) => {
  const { logout } = useLogout()
  const [categories, setCategories] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const fetchCategories = useCallback(() => {
    const config = {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/store-category`, config)
      .then((response) => {
        setCategories(response?.data?.data)
        return response?.data?.data
      })
      .catch((error) => {
        console.error('Error fetching categories:', error)
        if (error?.response?.data?.statusCode === 401) {
          logout()
          // enqueueSnackbar(error.response.data.message, {
          //   variant: 'error',
          // })
        } else {
          enqueueSnackbar('Error fetching categories', {
            variant: 'error',
          })
        }
        throw error
      })
  }, [enqueueSnackbar])

  useEffect(() => {
    if (window.location.pathname !== '/') {
      fetchCategories()
    }
  }, [fetchCategories])

  // const addCategory = useCallback(
  //   (newCategory) => {
  //     setCategories((prevCategories) => [...prevCategories, newCategory])
  //     navigate(`/admin/store/category/${newCategory?.id}`)
  //   },
  //   [navigate],
  // )

  const addCategory = useCallback(
    (newCategoryOrCategories) => {
      setCategories((prevCategories) => {
        if (Array.isArray(newCategoryOrCategories)) {
          return newCategoryOrCategories
        } else {
          return [...prevCategories, newCategoryOrCategories]
        }
      })

      const lastCategory = Array.isArray(newCategoryOrCategories)
        ? newCategoryOrCategories[newCategoryOrCategories.length - 1]
        : newCategoryOrCategories
      navigate(`/admin/store/category/${lastCategory?.id}`)
    },
    [navigate],
  )

  // const updateCategory = useCallback((newCategory) => {
  //   setCategories(newCategory)
  // }, [])

  const updateCategory = useCallback((updatedCategoryOrCategories) => {
    if (Array.isArray(updatedCategoryOrCategories)) {
      setCategories(updatedCategoryOrCategories)
    } else {
      setCategories((prevCategories) =>
        prevCategories.map((category) =>
          category.id === updatedCategoryOrCategories.id
            ? updatedCategoryOrCategories
            : category,
        ),
      )
    }
  }, [])

  const getCategoryById = useCallback(
    (id) => {
      return Array.isArray(categories)
        ? categories.find((category) => category?.id === id)
        : undefined
    },
    [categories],
  )

  return (
    <CategoryContext.Provider
      value={{
        categories,
        fetchCategories,
        addCategory,
        updateCategory,
        getCategoryById,
      }}
    >
      {children}
    </CategoryContext.Provider>
  )
}

export const useCategories = () => useContext(CategoryContext)
