import { Box, styled } from '@mui/material'
import React from 'react'
import '../css/DailyView.css'

const Logo = styled('svg')({
  position: 'absolute',
  width: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '& text': {
    stroke: 'white',
    fontSize: '60px',
    strokeWidth: '2px',
    fontWeight: 700,
    letterSpacing: '2px',
    fontFamily: 'GeneralSans-Variable, sans-serif',
    animation: 'textAnimate 3s alternate forwards',
  },
  '@keyframes textAnimate': {
    '0%': {
      strokeDasharray: '0 50%',
      fill: 'transparent',
    },
    '100%': {
      strokeDasharray: '50% 0',
      fill: 'white',
    },
  },
  '@media (max-width:600px)': {
    '& text': {
      fontSize: '20px',
    },
  },
})

const HomeLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#000',
      }}
    >
      <Logo id="logo">
        <text x="50%" y="50%" fill="#FFFFFF" textAnchor="middle">
          Light Body Technology
        </text>
      </Logo>
    </Box>
  )
}

export default HomeLoader
